document.addEventListener('DOMContentLoaded', function() {

  document.querySelectorAll('header .button.menu').forEach(function(el) {
    el.addEventListener('click', function(e) {
      e.preventDefault();

      var menu = document.querySelector('#menu');
      if (menu.classList.contains('visible')) {
        menu.classList.remove('visible');
      } else {
        menu.classList.add('visible');
      }
    });
  });

});
